
$(document).on('click', '.login-numpad', function () {
  let tokenInput = $('.login-code');
  tokenInput.val(tokenInput.val() + $(this).data("number"));

  if (tokenInput.val().length >= 4) {
    $(this).closest("form").submit();
    $(".overlay").css("display", "flex");
  }
})

$(document).on('click', '.clear-login-form', function () {
  let tokenInput = $('.login-code');
  tokenInput.val(tokenInput.val().slice(0, -1));
});
