let completedItems = [];

function updateCompletedItems(article_id = null) {

  if (article_id != null && completedItems.indexOf(article_id) === -1) {
    completedItems.push(article_id);

    $('.transaction-overview[data-article-id="' + article_id + '"]')
      .find('td:first-child')
      .append(' <i class="fas fa-check"></i>');
  }

  $(".items-completed").find('.count').text(completedItems.length);
}

$(document).on("click", ".hidden-select-btn", function() {
  var element = $("select.hidden-select");
  var elementId = $(this).data('id');

  $('.hidden-select-btn.btn-primary').removeClass('btn-primary').addClass('btn-light');
  $(this).removeClass('btn-light').addClass('btn-primary');

  element.val(elementId).trigger("change");
})

$(document).on('click', '.item-transaction-btn', function() {
  if ($(this).hasClass('disabled')) return;

  let index = $(this).data('index');

  if (index > -1) {
    $('.item-block').addClass('d-none');
    $('.item-block[data-step="' + index + '"]').removeClass('d-none');

    if ($(this).data('jumps-to') != undefined) {
      let article_id = $(this).data('jumps-to');
      $('html, body').animate({
          scrollTop: $('.transaction-overview[data-article-id="' + article_id + '"]').offset().top - 74
      }, 0);
    }
  } else {
    window.location = $(this).data('url');
  }
})

function validateButtons(value, container) {
  let operators = ["*", "+", "-"];
  let operatorsWithComma = operators + [","]
  let lastChar = value.at(-1);

  container.find('.storage-transaction-calculator-btn').each(function() {
    let number = $(this).data("number");

    if (value.length == 0 && operatorsWithComma.includes(number)) {
      $(this).addClass('disabled');
    } else if (operatorsWithComma.includes(lastChar) && operatorsWithComma.includes(number)) {
      $(this).addClass('disabled');
    } else if (/,\d{2}$/.test(value) && !operators.includes(number)) {
      $(this).addClass('disabled');
    } else {
      $(this).removeClass('disabled');
    }
  });
}

function containsOperator(value) {
  let operators = ["*", "+", "-"];

  for (let operator of operators) {
    if (value.includes(operator)) {
      return true;
    }
  }
  return false;
}

function updateCalculatorView(value, container) {
  let articleId = container.data('article-id');
  let element = container.find('input[name="quantity"]');
  let sum = container.find('.sum');
  let warning = container.find('.validations-container');
  let maxQuantity = parseFloat(element.data('max-quantity'));

  element.val(value)

  validateButtons(value, container);

  let expression = value
  expression = expression.replaceAll(',', '.') // replace comma with dot
  expression = expression.replace(/\D$/, '') // remove last character if it's not a number
  let quantity = eval(expression);
  if (quantity === undefined) {
    quantity = 0
  }

  if (quantity < 0) {
    warning.removeClass('invisible');
    warning.html('Menge darf nicht negativ werden')
    container.find('.continue-btn').addClass('disabled');
    container.find('.list-btn').addClass('disabled');
  } else if (!isNaN(maxQuantity) && quantity > maxQuantity) {
    warning.removeClass('invisible');
    warning.html('Bestand ist kleiner als die eingegebene Menge')
    container.find('.continue-btn').addClass('disabled');
    container.find('.list-btn').addClass('disabled');
  } else {
    warning.addClass('invisible');
    warning.html('');
    container.find('.continue-btn').removeClass('disabled');
    container.find('.list-btn').removeClass('disabled');
  }

  if (containsOperator(value.replace(/\D$/, ''))) {
    sum.find('.number').html(quantity)
    sum.removeClass('d-none');
  } else {
    sum.addClass('d-none');
  }

  if ($('#storage_transaction_kind').val() == "inventory") {
    updateInventoryValues(articleId, quantity, false);
  } else {
    updateItemValues(articleId, quantity, false);
  }
}

$(document).on('click', '.storage-transaction-calculator-btn', function() {
  if ($(this).hasClass('disabled')) return;

  let parent = $(this).closest('.item-block');
  let element = parent.find('input[name="quantity"]');
  let newValue = element.val() + $(this).data("number");

  updateCalculatorView(newValue, parent);
});

$(document).on('click', '.clear-number', function() {
  let parent = $(this).closest('.item-block');
  let element = parent.find('input[name="quantity"]');
  let newValue = element.val().slice(0, -1);

  updateCalculatorView(newValue, parent);
});

$(document).on('click', '.item-transaction-quantity-increase-btn', function() {
  changeTransactionItemQuantity(this, 1);
})

$(document).on('click', '.item-transaction-quantity-decrease-btn', function() {
  changeTransactionItemQuantity(this, -1);
})

function changeTransactionItemQuantity(element, multiplier) {
  let transaction_overview_container = $(element).closest('.transaction-overview');
  let source_element = $(transaction_overview_container).find('.source_storage_quantity');
  let target_element = $(transaction_overview_container).find('.target_storage_quantity');

  let article_id = transaction_overview_container.data('article-id');
  let old_transaction_quantity = normalizeNumber($('.input-container[data-article-id="' + article_id + '"] .quantity').val());

  if (multiplier === 1 || (multiplier === -1 && old_transaction_quantity > 0)) {
    let new_transaction_quantity = normalizeNumber(old_transaction_quantity + multiplier);

    if (new_transaction_quantity < 0) {
      new_transaction_quantity = 0;
    }

    if (new_transaction_quantity > source_element.data('source-storage-quantity')) {
      new_transaction_quantity = source_element.data('source-storage-quantity');
    }

    if (source_element.data('source-storage-quantity') - new_transaction_quantity < 0) return;
    if (target_element.data('target-storage-quantity') + new_transaction_quantity < 0) return;

    updateItemValues(article_id, new_transaction_quantity);
  }
}

$(document).on('click', '.item-storage-quantity-increase-btn', function() {
  changeInventoryItemQuantity(this, 1);
})

$(document).on('click', '.item-storage-quantity-decrease-btn', function() {
  changeInventoryItemQuantity(this, -1);
})

function changeInventoryItemQuantity(element, multiplier) {
  let transaction_overview_container = $(element).closest('.transaction-overview');
  let items_quantity = normalizeStringNumber($(transaction_overview_container).find('.quantity').html());
  let article_id = transaction_overview_container.data('article-id');

  if (items_quantity == '-') {
    items_quantity = 0;
  }

  if (multiplier === 1 || multiplier === -1) {
    items_quantity = normalizeNumber(items_quantity) + multiplier;

    if (items_quantity < 0) {
      items_quantity = 0;
    }

    updateInventoryValues(article_id, items_quantity);
  }
}


$(document).on('click', '.storage_quantity', function() {
  let items_quantity = $(this).html();

  if (items_quantity == '-') {
    items_quantity = '0';
  }

  if (!$(this).find('.input_storage_quantity').length > 0) {
    $(this).html('<input class="input_storage_quantity form-control text-center" inputmode="numeric" type="number" style="height:24.75px; padding: 0px;" value="' + items_quantity.replace(',', '.') + '" />');
    let input = $(this).find('.input_storage_quantity')[0];
    input.type = 'text'
    input.value = items_quantity

    let end = items_quantity.length; // Länge des aktuellen Wertes im Input
    let start = items_quantity === '0' ? 0 : end;
    input.setSelectionRange(start, end); // Setzt den Cursor ans Ende
    input.focus(); // Setzt den Fokus auf das Input-Element, um den Cursor sichtbar zu machen
  }
})

$(document).on('keydown', "input.input_storage_quantity", function (event) {
  if (event.which == 13 || event.which == 27) { // enter or escape
    $(this).trigger('focusout');
  }
  validateItemQuantityFromInput(event);
  $(event.target).removeClass('selected');
});

$(document).on('focusout', 'input.input_storage_quantity', function() {
  let items_quantity = $(this).val();

  if (items_quantity == '') $(this).val(0);
  changeInventoryItemQuantityFromInput(this);
  if (items_quantity <= 0) items_quantity = '-';
  $(this).parent().html(items_quantity);
})

function changeInventoryItemQuantityFromInput(element) {
  let transaction_overview_container = $(element).closest('.transaction-overview');
  let articleId = transaction_overview_container.data('article-id');
  let quantity = normalizeNumber(normalizeStringNumber($(element).val()));

  if ($('#storage_transaction_kind').val() == "inventory") {
    updateInventoryValues(articleId, quantity);
  } else {
    updateItemValues(articleId, quantity);
  }
}

function validateItemQuantityFromInput(event) {
  const key = event.key;
  const input = event.target;
  const currentValue = input.value.substring(0, input.selectionStart) + key + input.value.substring(input.selectionEnd);

  if (
    ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'End', 'Home'].includes(key) ||
    (key === ',' && currentValue.indexOf(',') != 0 && currentValue.indexOf(',') == currentValue.lastIndexOf(',')) ||
    RegExp("^[0-9]+(,[0-9]{0,2})?$").test(currentValue)
  ) {
    return true;
  }

  event.preventDefault();
  return false;
};

function updateInventoryValues(article_id, items_quantity, updateCalculatorView = true) {
  let transaction_overview_container = $('.transaction-overview[data-article-id="' + article_id + '"]');
  let source_element = $(transaction_overview_container).find('.source_storage_quantity');
  let difference_element = $(transaction_overview_container).find('.difference_storage_quantity');

  let source_quantity = parseFloat(source_element.data('source-storage-quantity'));

  let input_container = $('.input-container[data-article-id="' + article_id + '"]')
  let item_block = $('.item-block[data-article-id="' + article_id + '"]')

  if (updateCalculatorView) {
    let displayValue = items_quantity.toString().replace('.', ',')
    item_block.find('input[name="quantity"]').val(displayValue);
    validateButtons(displayValue, item_block);
  }

  transaction_overview_container.find('.storage_quantity').html(convertNumberToStringQuantity(items_quantity));

  let items_quantity_normalized = normalizeNumber(items_quantity);
  let transaction_quantity = items_quantity_normalized - source_quantity;
  difference_element.html(convertNumberToString(transaction_quantity));
  input_container.find('.quantity').val(transaction_quantity);

  difference_element.removeClass('text-danger');
  if (transaction_quantity < 0) {
    difference_element.addClass('text-danger');
  }

  updateCompletedItems(article_id);
}

function updateItemValues(article_id, transaction_quantity, updateCalculatorView = true) {
  let transaction_overview_container = $('.transaction-overview[data-article-id="' + article_id + '"]');
  let source_element = $(transaction_overview_container).find('.source_storage_quantity');
  let target_element = $(transaction_overview_container).find('.target_storage_quantity');

  let source_quantity = parseFloat(source_element.data('source-storage-quantity'));
  let target_quantity = parseFloat(target_element.data('target-storage-quantity'));

  if (source_quantity - transaction_quantity < 0) {
    transaction_quantity = source_quantity;
  }

  let input_container = $('.input-container[data-article-id="' + article_id + '"]');
  let item_block = $('.item-block[data-article-id="' + article_id + '"]');

  if (updateCalculatorView) {
    let displayValue = transaction_quantity.toString().replace('.', ',');
    item_block.find('input[name="quantity"]').val(displayValue);
    validateButtons(displayValue, item_block);
  }

  transaction_overview_container.find('.storage_quantity').html(convertNumberToString(transaction_quantity));

  let transaction_quantity_normalized = normalizeNumber(transaction_quantity);
  source_element.html(convertNumberToString(source_quantity - transaction_quantity_normalized));
  target_element.html(convertNumberToString(target_quantity + transaction_quantity_normalized));
  input_container.find('.quantity').val(transaction_quantity_normalized);

  updateCompletedItems(article_id);
}

function convertNumberToString(number) {
  if (number == 0) {
    return '-';
  }

  return normalizeNumber(number).toString().replace('.', ',');
}

function convertNumberToStringQuantity(number) {
  return normalizeNumber(number).toString().replace('.', ',');
}

function normalizeStringNumber(value) {
  return value.toString().replace(',', '.');
}

function normalizeNumber(number) {
  return parseFloat(parseFloat(number).toFixed(2));
}
