function updateTimer() {
  $.ajax({
    url: "/employee/logins",
    type: "patch",
    dataType: "json"
  });
}

$(document).on('turbolinks:load', function () {
  if ($(".check-session").length > 0 ) {
    if (!window.checkoutInterval) {
      window.checkoutInterval = setInterval(function () {
        $.ajax({
          url: "/employee/logins/check",
          dataType: "json"
        }).done(function (data) {
          if (data.reload) {
            window.location = $(".check-session").data("url")
            clearInterval(window.checkoutInterval)
          }
        });
      }, 2000);
    }

    $(document).on('touchstart', function () {
      updateTimer();
    });

    $(document).on('mousedown', function () {
      updateTimer();
    });
  };
});


