function sendRequest(source_filter) {
  let data = $(".storages_contents_form").serialize();
  if (source_filter) {
    data += "&source_filter=" + source_filter;
  }

  $.ajax({
    type: 'GET',
    url: '',
    data: data,
    dataType: "script"
  });
}

$(document).on("change", ".storages_contents_form select:not(#articles_branches_storage_branch_ids):not(#articles_branches_storage_storage_ids)", function () {
  sendRequest();
});

$(document).on("change", "#articles_branches_storage_branch_ids", function () {
  sendRequest("branches");
});

$(document).on("change", "#articles_branches_storage_storage_ids", function () {
  sendRequest("storages");
});
