$(document).on("change", "#task_kind", function() {
  let primaryTitle = $('.task_title input').data('primary-title')
  let secondaryTitle = $('.task_title input').data('secondary-title')

  if ($(this).val() == "value") {
    $('.task_value').parent().removeClass("d-none");
    $('.task_supplier_ids').parent().addClass("d-none");
    $('.task_storage_ids').parent().addClass("d-none");
    $('.task_recipient_email').parent().addClass("d-none");
    $('.task_requires_photo').parent().removeClass("d-none");
    $('.task_title label').html(primaryTitle + ' <abbr>*</abbr>');
  } else if ($(this).val() == "order") {
    $('.task_supplier_ids').parent().removeClass("d-none");
    $('.task_storage_ids').parent().addClass("d-none");
    $('.task_value').parent().addClass("d-none");
    $('.task_recipient_email').parent().addClass("d-none");
    $('.task_requires_photo').parent().addClass("d-none");
    $('.task_title label').html(primaryTitle + ' <abbr>*</abbr>');
  } else if ($(this).val() == "inventory") {
    $('.task_supplier_ids').parent().addClass("d-none");
    $('.task_storage_ids').parent().removeClass("d-none");
    $('.task_value').parent().addClass("d-none");
    $('.task_recipient_email').parent().addClass("d-none");
    $('.task_requires_photo').parent().addClass("d-none");
    $('.task_title label').html(primaryTitle + ' <abbr>*</abbr>');
  } else if ($(this).val() == "confirmation") {
    $('.task_supplier_ids').parent().addClass("d-none");
    $('.task_storage_ids').parent().addClass("d-none");
    $('.task_value').parent().addClass("d-none");
    $('.task_recipient_email').parent().addClass("d-none");
    $('.task_requires_photo').parent().removeClass("d-none");
    $('.task_title label').html(primaryTitle + ' <abbr>*</abbr>');
  } else {
    $('.task_supplier_ids').parent().addClass("d-none");
    $('.task_storage_ids').parent().addClass("d-none");
    $('.task_value').parent().addClass("d-none");
    $('.task_recipient_email').parent().removeClass("d-none");
    $('.task_requires_photo').parent().removeClass("d-none");
    $('.task_title label').html(secondaryTitle + ' <abbr>*</abbr>');
  }
})

$(document).on("click", ".open-task, .complete-task", function(event) {
  event.preventDefault();
  $("#todo_status").val($(this).data("status"));
  $("form.edit_todo").submit();
})

$(document).on("input", "#todo_value", function() {
  var value = parseFloat($(this).val())
  var tolerance_range = $(this).data("value")

  if (value < tolerance_range[0] || value > tolerance_range[1]) {
    $(this).parents(".form-inputs").find("#todo_measure").parent().removeClass("d-none")
  } else {
    $(this).parents(".form-inputs").find("#todo_measure").parent().addClass("d-none")
  }
})

$(document).on("click", ".remote-form-container .submit-task", function(event) {
  var form = $(this).parents("form");
  var formData = new FormData(form[0]);
  event.preventDefault()

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    data: formData,
    dataType: "script",
    processData: false,
    contentType: false,
    success: function() {
      window.initialize()
    }
  })
})

$(document).on("change", "#load-branches", function(event) {
  event.preventDefault()

  $.ajax({
    url: $(this).data("url"),
    type: "get",
    data: $(this).parents("form").serialize(),
    dataType: "script"
  })
})

$(document).on("change", "#task_one_time", function() {
  change_interval_options_visibility();
  if ($('#task_one_time')[0].checked) {
    $('label[for="task_start_date"]').append(' <abbr title="">*</abbr>');
  } else {
    $('label[for="task_start_date"]').find('abbr').remove();
  }
})

$(document).on("change", "#task_weekdays", function() {
  change_interval_options_visibility()
})

$(document).on("click", ".btn-toggle-impossible", function(event) {
  event.preventDefault()

  let task = $(this).closest('tr');
  let taskId = $(this).closest('tr').data('id');

  task.find('#edit_todo_' + taskId).toggle(".d-none")
  task.find('#impossible_edit_todo_' + taskId).toggle(".d-none")
});

function change_interval_options_visibility() {
  if ($('#task_one_time')[0].checked) {
    $('.task_weekdays').addClass("d-none");
    $('.task_weekly_interval').addClass("d-none");
    $('.task_interval').addClass("d-none");
  } else {
    $('.task_weekdays').removeClass("d-none");
    if ($('#task_weekdays').val() == "") {
      $(".select.task_interval").removeClass("d-none")
      $(".select.task_weekly_interval").addClass("d-none")
    } else {
      $(".select.task_interval").addClass("d-none")
      $(".select.task_weekly_interval").removeClass("d-none")
    }
  }
}

$(document).on("change", "select[id^='task_due_time']", function() {
  let validFromTimeHour = $("select[id^='task_valid_from_time']")[0].value,
      validFromTimeMinute = $("select[id^='task_valid_from_time']")[1].value,
      dueTimeHour = $("select[id^='task_due_time']")[0].value,
      dueTimeMinute = $("select[id^='task_due_time']")[1].value;

  if ($("#task_due_on_day").val() != 0
      || validFromTimeHour < dueTimeHour
      || (validFromTimeHour == dueTimeHour && validFromTimeMinute < dueTimeMinute)) {

      return;
  }

  // Change to the following day
  $("#task_due_on_day").val(1).trigger("change");
});

$(document).on("change", "#task_requires_photo", function() {
  if ($('#task_requires_photo')[0].checked) {
    $(".task_photo_email").parent().removeClass("d-none");
  } else {
    $('.task_photo_email').parent().addClass("d-none");
  }
});
