function displayResponseForUser(response) {
  response.json()
    .then((data) => {

      if (response.ok) {
        displayFlashMessage(data.message, "success");
      } else {
        displayFlashMessage(data.message, "danger");
      }

    });
}

async function fetchServerKey() {
  return await fetch("/manifest.json")
    .then((response) => response.json())
    .then((manifest) => manifest.applicationServerKey);
}

function isNotificationSupported() {
  const isIDevice = /iphone|ipod|ipad|macintosh/i.test(navigator.userAgent);
  const isSafari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("CriOS") < 0;
  const isStandalone = 'standalone' in navigator && navigator.standalone;

  // Not supported if not installed on home screen on iOS Safari
  if (isIDevice && isSafari && !isStandalone) {
    return false;
  }

  return ("serviceWorker" in navigator && "PushManager" in window && "Notification" in window);
}

async function registerServiceWorker() {
  return await navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {

        console.log('Service worker successfully registered:', registration);
        return registration;

    });
}

async function unregisterServiceWorker() {
  return await navigator.serviceWorker
    .getRegistrations()
    .then(async (registrations) => {

      for (let registration of registrations) {
        if (registration.scope.includes(window.location.origin)) {
          console.log('Unregistering service worker:', registration);
          return await registration.unregister();
        }
      }

      return false;

    });
}

async function subscribeToNotifications() {
  return await navigator.serviceWorker.ready
    .then(async (serviceWorker) => {

      return await serviceWorker.pushManager
        .getSubscription()
        .then(async (existingSubscription) => {

          if (existingSubscription) {
            let found = await checkSubscription(existingSubscription)

            if (found) {
              return existingSubscription;
            }

            existingSubscription.unsubscribe();
          }

          return await fetchServerKey()
            .then(async (serverKey) => {

              if (serverKey) {
                return await serviceWorker.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: serverKey
                  })
                  .then(async (subscription) => {

                    await saveSubscription(subscription);
                    return subscription;

                  });
              }

            });

        });

    });
}

async function checkSubscription(subscription) {
  const endpoint = subscription.endpoint;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({ endpoint }),
  }

  return await fetch("/user/push_subscriptions/find", options)
    .then((response) => {

      return (response.ok);

    });
}

async function saveSubscription(subscription) {
  // Extract necessary subscription data
  const endpoint = subscription.endpoint;
  const p256dh = btoa(
    String.fromCharCode.apply(
      null,
      new Uint8Array(subscription.getKey("p256dh"))
    )
  );
  const auth = btoa(
    String.fromCharCode.apply(
      null,
      new Uint8Array(subscription.getKey("auth"))
    )
  );
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({ endpoint, p256dh, auth }),
  }

  // Send the subscription data to the server
  return await fetch("/user/push_subscriptions/subscribe", options)
    .then((response) => {

      if (response.ok) {
        console.log("Subscription successfully saved on the server.");
      } else {
        console.error("Error saving subscription on the server.");

        unsubscribeFromNotifications(false)
          .then(() => {

            unregisterServiceWorker();
            updateNotificationsButtons();

          });
      }

      displayResponseForUser(response);

      return response;

    })
    .catch((error) => console.error("Error sending subscription to the server:", error));
}

async function unsubscribeFromNotifications(remove_from_server = true) {
  return await navigator.serviceWorker.ready
    .then(async () => {

      return await navigator.serviceWorker.getRegistration()
        .then(async (registration) => {

          if (!registration) return true;

          return await registration.pushManager
            .getSubscription()
            .then(async (subscription) => {

              if (!subscription) return true;

              return await subscription.unsubscribe()
                .then(async () => {

                  return remove_from_server ? await removeSubscription(subscription) : true;

                });

            });

        });

    });
}

async function removeSubscription(subscription) {
  const endpoint = subscription.endpoint;
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({ endpoint }),
  }

  return await fetch("/user/push_subscriptions/unsubscribe", options)
    .then((response) => {

      if (response.ok) {
        console.log("Subscription successfully removed from the server.");
      } else {
        console.error("Error removing subscription from the server.");
      }

      displayResponseForUser(response);

      return response;

    })
    .catch((error) => console.error("Error sending subscription to the server:", error));
}

async function sendTestNotification() {
  return await fetch("/user/push_subscriptions/test_message")
    .then((response) => {

      if (response.ok) {
        console.log("Test notification sent.");
      } else {
        console.error("Error sending test notification.");
      }

      displayResponseForUser(response);

      return response;

    })
    .catch((error) => console.error("Error sending test notification:", error));
}

function updateNotificationsButtons() {
  if (!isNotificationSupported()) {
    $(".notifications-subscribe-btn").addClass("disabled d-none");
    $(".notifications-unsubscribe-btn").addClass("d-none");
    $(".notifications-test-btn").addClass("d-none");
    return;
  }

  navigator.serviceWorker.ready
    .then((serviceWorker) => {

      serviceWorker.pushManager.getSubscription()
        .then((subscription) => {

          if (subscription) {
            $(".notifications-subscribe-btn").addClass("disabled d-none");
            $(".notifications-test-btn").removeClass("disabled d-none");
            $(".notifications-unsubscribe-btn").removeClass("disabled d-none");
          } else {
            $(".notifications-subscribe-btn").removeClass("disabled d-none");
            $(".notifications-test-btn").addClass("disabled d-none");
            $(".notifications-unsubscribe-btn").addClass("disabled d-none");
          }

        });

    });
}

$(document).on("click", ".notifications-subscribe-btn", function(event) {
  event.preventDefault();
  event.stopPropagation();

  if ($(this).hasClass("disabled") || !isNotificationSupported()) {
    return;
  }

  if (Notification.permission === "denied") {

    dataConfirmModal.confirm({
      title: 'PHiLUP-Benachrichtigungen deaktiviert',
      text: "Bitte erlauben Sie Benachrichtigungen in den Browsereinstellungen, um PHiLUP-Benachrichtigungen zu erhalten.",
      commit: 'Anleitung',
      onConfirm: function() {
        window.open("https://philup.de/#Benachrichtigungen", "_blank");
      }
    });
    $('button').remove('.cancel');

    return;
  }

  if (Notification.permission === "granted") {
    registerServiceWorker()
      .then(() => subscribeToNotifications()
        .then(() => {
          updateNotificationsButtons();
          console.log("Push notifications activation complete.");
        })
      );

    return;
  }

  Notification.requestPermission().then((permission) => {

    if (permission === "granted") {
      registerServiceWorker()
        .then(() => subscribeToNotifications()
          .then(() => {
            updateNotificationsButtons();
            console.log("Push notifications activation complete.");
          })
        );
    } else {
      displayFlashMessage("Berechtigung zum Senden von Benachrichtigungen verweigert.", "danger");
    }

  });

});

$(document).on("click", ".notifications-unsubscribe-btn", function(event) {
  event.preventDefault();
  event.stopPropagation();

  if ($(this).hasClass("disabled") || !isNotificationSupported()) {
    return;
  }

  unsubscribeFromNotifications()
    .then(() => unregisterServiceWorker()
      .then(() => {
        updateNotificationsButtons();
        console.log("Push notifications deactivation complete.");
      })
    );
});

$(document).on("click", ".notifications-test-btn", function() {
  if ($(this).hasClass("disabled") || !isNotificationSupported()) {
    return;
  }

  sendTestNotification();
});

document.addEventListener("DOMContentLoaded", () => {
  updateNotificationsButtons();

  const push_notifications_match = document.cookie.match(/push_notifications=(subscribe|unsubscribe)/)

  if (!push_notifications_match) {
    return;
  }

  const [cookie, action] = push_notifications_match;
  document.cookie = cookie + "; Path=/; SameSite=strict; Expires=Thu, 01 Jan 1970 00:00:00 UTC;"; // Delete cookie

  if (!isNotificationSupported()) {
    return;
  }

  if (action === "subscribe") {
    if (Notification.permission === "denied") {
      return;
    }

    if (Notification.permission === "granted") {
      registerServiceWorker()
        .then(() => subscribeToNotifications()
          .then(() => {
            updateNotificationsButtons();
            console.log("Push notifications activation complete.");
          })
        );

      return;
    }

    dataConfirmModal.confirm({
      title: 'PHiLUP-Benachrichtigungen',
      text: "Möchten Sie Benachrichtigungen von PHiLUP erhalten?",
      commit: 'Ja, gerne',
      cancel: 'Nein, danke',
      onConfirm: function() {

        Notification.requestPermission().then((permission) => {

          if (permission === "granted") {
            registerServiceWorker()
              .then(() => subscribeToNotifications()
                .then(() => {
                  updateNotificationsButtons();
                  console.log("Push notifications activation complete.");
                })
              );
          } else {
            displayFlashMessage("Berechtigung zum Senden von Benachrichtigungen verweigert.", "danger");
          }

        });

      },
      onCancel:  function() {},
    });
  }

  if (action === "unsubscribe") {
    unsubscribeFromNotifications()
      .then(() => unregisterServiceWorker()
        .then(() => {
          updateNotificationsButtons();
          console.log("Push notifications deactivation complete.");
        })
      );
  }
});