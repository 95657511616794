var current_stock;
$(document).on('turbolinks:load', function() {
  if ($('.suppliers-container').length > 0) {
    current_stock = $('.suppliers-container').data('minimum-order-values')

    for (var supplier_id in current_stock) {
      current_stock[supplier_id].current_order_value = parseFloat(current_stock[supplier_id].current_order_value)
      current_stock[supplier_id].minimum_order_value = parseFloat(current_stock[supplier_id].minimum_order_value)

      setColorForValues(supplier_id)
    }
  }
});

$(document).on('click', '.article-order-btn', function() {
  let index = $(this).data('index')

  if (index > -1) {
    $('.article-order-block').addClass('d-none')
    $('.article-order-block[data-step="' + index + '"]').removeClass('d-none')
  } else {
    window.location = $(this).data('url');
  }
})

$(document).on('click', '.product-order-stock-increase-btn', function() {
  var parent = $(this).parents('.article-order-block')

  changeOrderBoxesQuantity($('.order-overview[data-article-id="' + parent.data("article-id") + '"] .article-order-stock-increase-btn'), 1)
})

$(document).on('click', '.product-order-stock-decrease-btn', function() {
  var parent = $(this).parents('.article-order-block')

  changeOrderBoxesQuantity($('.order-overview[data-article-id="' + parent.data("article-id") + '"] .article-order-stock-increase-btn'), -1)
})

$(document).on('click', '.article-order-stock-increase-btn', function() {
  changeOrderBoxesQuantity(this, 1)
})

$(document).on('click', '.article-order-stock-decrease-btn', function() {
  changeOrderBoxesQuantity(this, -1)
})

function changeOrderBoxesQuantity(element, multiplicator) {
  var order_overview_container = $(element).closest('.order-overview')
  var order_overview_element = $(order_overview_container).find('.order_quantity')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .order_quantity').val())

  if (multiplicator === 1 || order_overview_amount > 0) {
    var new_order_amount = order_overview_amount + multiplicator
    updateMinOrderAmount(new_order_amount, article_id)

    order_overview_element.html(new_order_amount)
    $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_order_amount)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_order_amount)
  }
}

$(document).on('click', '.product-order-target-increase-btn', function() {
  var parent = $(this).parents('.article-order-block')

  changeOrderTargetQuantity($('.order-overview[data-article-id="' + parent.data("article-id") + '"] .article-order-target-increase-btn'), 1)
})

$(document).on('click', '.product-order-target-decrease-btn', function() {
  var parent = $(this).parents('.article-order-block')

  changeOrderTargetQuantity($('.order-overview[data-article-id="' + parent.data("article-id") + '"] .article-order-target-increase-btn'), -1)
})

$(document).on('click', '.article-order-target-increase-btn', function() {
  changeOrderTargetQuantity(this, 1)
})

$(document).on('click', '.article-order-target-decrease-btn', function() {
  changeOrderTargetQuantity(this, -1)
})

function changeOrderTargetQuantity(element, multiplicator) {
  var order_overview_container = $(element).closest('.order-overview')
  var order_overview_element = $(order_overview_container).find('.target_quantity')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .target_quantity').val())

  if (multiplicator === 1 || order_overview_amount > 0) {
    var new_target_amount = order_overview_amount + multiplicator

    updateMinOrderAmount(new_target_amount, article_id)
    order_overview_element.html(new_target_amount)
    $('.input-container[data-article-id="' + article_id + '"] .target_quantity').val(new_target_amount)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="target_stock"]').val(new_target_amount)

    if ($(order_overview_container).find('.multiplied_target_quantity').length == 0) {
      // update order quantity
      var order_stock_element = $('.product-stock[data-article-id="'  + article_id + '"] input[name="actual_quantity"]')
      if (order_stock_element.val() !== "" || order_stock_element.is(":hidden")) {
        if (order_stock_element.is(":hidden")) {
          var order_stock_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val())
        } else {
          var order_stock_amount = parseInt(order_stock_element.val())
        }

        if (order_stock_amount <= new_target_amount) {
          $(order_overview_container).find('.order_quantity').html(new_target_amount - order_stock_amount)
          $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_target_amount - order_stock_amount)
          $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_target_amount - order_stock_amount)
        }
      }
    }
  }
}

$(document).on('click', '.update-article-number', function() {
  let parent = $(this).closest('.article-order-block');
  let element = parent.find('input[name="actual_quantity"]');

  element.val(element.val() + $(this).data("number"));

  // update order quantity
  var article_id = parent.data('article-id')
  var order_overview_element = $('.order-overview[data-article-id="' + article_id + '"]')

  if ($(order_overview_element).find('.multiplied_target_quantity').length > 0) {
    var order_target_element = $(order_overview_element).find('.multiplied_target_quantity')
  } else {
    var order_target_element = $(order_overview_element).find('.target_quantity')
  }

  var order_target_amount = parseInt(order_target_element.html())
  var order_stock_amount = parseInt(element.val())

  $(order_overview_element).find('.actual_quantity').html(order_stock_amount)

  if (order_stock_amount <= order_target_amount) {
    $(order_overview_element).find('.order_quantity').html(order_target_amount - order_stock_amount)
    $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(order_target_amount - order_stock_amount)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(order_target_amount - order_stock_amount)
  } else {
    $(order_overview_element).find('.order_quantity').html(0)
    $(order_overview_element).find('.actual_quantity').html(order_stock_amount)
    $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(0)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(0)
  }
  $('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val(order_stock_amount)

  updateMinOrderAmount(order_target_amount - order_stock_amount, article_id)
});

$(document).on('click', '.clear-article-number', function() {
  let parent = $(this).closest('.article-order-block');
  let element = parent.find('input[name="actual_quantity"]');

  element.val('');

  var article_id = parent.data('article-id')
  var order_overview_element = $('.order-overview[data-article-id="' + article_id + '"]')

  $(order_overview_element).find('.order_quantity').html(0)
  $(order_overview_element).find('.actual_quantity').html(0)
  $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(0)
  $('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val(0)
  $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(0)
});

$(document).on('change', '#order_supplier_ids', function() {
  if ($(".quick-order").length == 0) { return }

  let url = decodeURIComponent($('.quick-order').data("url"))
  var href = url.replace("{{ids}}", $(this).val().join("&order[supplier_ids][]="))

  $('.quick-order').attr("href", href)
})

$(document).on('click', '.article-order-actual-increase-btn', function() {
  changeActualBoxesQuantity(this, 1)
})

$(document).on('click', '.article-order-actual-decrease-btn', function() {
  changeActualBoxesQuantity(this, -1)
})

function changeActualBoxesQuantity(element, multiplicator) {
  var order_overview_container = $(element).closest('.order-overview')
  var order_overview_element = $(order_overview_container).find('.actual_quantity')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val())

  if (multiplicator === 1 || order_overview_amount > 0) {
    var new_order_amount = order_overview_amount + multiplicator

    order_overview_element.html(new_order_amount)
    $('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val(new_order_amount)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="actual_quantity"]').val(new_order_amount)

    if ($(order_overview_container).find('.multiplied_target_quantity').length > 0) {
      var target_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .multiplied_target_quantity').val())
    } else {
      var target_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .target_quantity').val())
    }

    new_order_amount = Math.max(target_amount - new_order_amount, 0)
    updateMinOrderAmount(new_order_amount, article_id)

    $(order_overview_container).find('.order_quantity').html(new_order_amount)
    $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_order_amount)
    $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_order_amount)
  }
}

function updateMinOrderAmount(count, article_id){
  if (current_stock === undefined) return

  var element = $('.input-container[data-article-id="' + article_id + '"]')
  var supplier_id = element.data('supplier-id')

  if (!current_stock[supplier_id]) return

  current_stock[supplier_id].amounts[article_id].order_quantity = count
  current_stock[supplier_id].current_order_value = 0

  for (var article_id in current_stock[supplier_id].amounts) {
    var order_object = current_stock[supplier_id].amounts[article_id]

    current_stock[supplier_id].current_order_value += order_object.order_quantity * order_object.price
  };

  setColorForValues(supplier_id)
}

function setColorForValues(supplier_id){
  var card_body = $('.suppliers-container .supplier[data-supplier-id="'  + supplier_id + '"] .card-body')
  var item = current_stock[supplier_id]
  $('.suppliers-container .supplier[data-supplier-id="' + supplier_id + '"] .current-order-value').html(item.current_order_value.toFixed(2) + ' €')

  if (item.current_order_value == 0){
    card_body.removeClass('bg-danger text-white')
  } else if(item.current_order_value < item.minimum_order_value){
    card_body.removeClass('bg-success')
    card_body.addClass('bg-danger text-white')
  } else {
    card_body.removeClass('bg-danger')
    card_body.addClass('bg-success text-white')
  }
}

$(document).on('click', '.order_quantity', function() {
  var order_overview_container = $(this).closest('.order-overview')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .order_quantity').val())

  $(this).html('<input class="input_order_quantity form-control text-center" type="text" style="height:24.75px; padding: 0px;" value="' + order_overview_amount + '" />')
  $(this).find('.input_order_quantity').trigger("select")
})

$(document).on("keyup", "input.input_order_quantity", delay(function (e) {
  changeOrderBoxesQuantityValueFromInput(this)
}, 500));

$(document).on('keypress', "input.input_order_quantity", function (event) {
  validateQuantityFromInput(event)
});

$(document).on('focusout', 'input.input_order_quantity', function() {
  changeOrderBoxesQuantityValueFromInput(this)
  order_amount = $(this).val()

  if (0 >= order_amount) {
    order_amount = '-'
  }

  $(this).parent().html(order_amount)
})

function changeOrderBoxesQuantityValueFromInput(element) {
  var order_overview_container = $(element).closest('.order-overview')
  var article_id = order_overview_container.data('article-id')
  var new_order_amount = $(element).val()

  if (0 > new_order_amount || !new_order_amount) {
    new_order_amount = 0
  }

  updateMinOrderAmount(new_order_amount, article_id)

  $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_order_amount)
  $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_order_amount)
}

$(document).on('click', '.target_quantity', function() {
  var order_overview_container = $(this).closest('.order-overview')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .target_quantity').val())

  $(this).html('<input class="input_target_quantity form-control text-center" type="text" style="height:24.75px; padding: 0px;" value="' + order_overview_amount + '" />')
  $(this).find('.input_target_quantity').trigger("select")
})

$(document).on("keyup", "input.input_target_quantity", delay(function (e) {
  changeOrderTargetQuantityFromInput(this)
}, 500));

$(document).on('keypress', "input.input_target_quantity", function (event) {
  validateQuantityFromInput(event)
});

$(document).on('focusout', 'input.input_target_quantity', function() {
  changeOrderTargetQuantityFromInput(this)
  order_amount = $(this).val()

  if (0 >= order_amount) {
    order_amount = '-'
  }

  $(this).parent().html(order_amount)
})

function changeOrderTargetQuantityFromInput(element) {
  var order_overview_container = $(element).closest('.order-overview')
  var order_overview_element = $(order_overview_container).find('.target_quantity')
  var article_id = order_overview_container.data('article-id')
  var new_target_amount = $(element).val()

  if (0 > new_target_amount || !new_target_amount) {
    new_target_amount = 0
  }

  updateMinOrderAmount(new_target_amount, article_id)
  order_overview_element.html(new_target_amount)
  $('.input-container[data-article-id="' + article_id + '"] .target_quantity').val(new_target_amount)
  $('.product-stock[data-article-id="'  + article_id + '"] input[name="target_stock"]').val(new_target_amount)

  if ($(order_overview_container).find('.multiplied_target_quantity').length == 0) {
    var order_stock_element = $('.product-stock[data-article-id="'  + article_id + '"] input[name="actual_quantity"]')
    if (order_stock_element.val() !== "" || order_stock_element.is(":hidden")) {
      if (order_stock_element.is(":hidden")) {
        var order_stock_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val())
      } else {
        var order_stock_amount = parseInt(order_stock_element.val())
      }

      if (order_stock_amount <= new_target_amount) {
        $(order_overview_container).find('.order_quantity').html(new_target_amount - order_stock_amount)
        $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_target_amount - order_stock_amount)
        $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_target_amount - order_stock_amount)
      }
    }
  }
}

$(document).on('click', '.actual_quantity', function() {
  var order_overview_container = $(this).closest('.order-overview')
  var article_id = order_overview_container.data('article-id')
  var order_overview_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val())

  $(this).html('<input class="input_actual_quantity form-control text-center" type="text" style="height:24.75px; padding: 0px;" value="' + order_overview_amount + '" />')
  $(this).find('.input_actual_quantity').trigger("select")
})

$(document).on("keyup", "input.input_actual_quantity", delay(function (event) {
  changeActualBoxesQuantityFromInput(this)
}, 500));

$(document).on('keypress', "input.input_actual_quantity", function (event) {
  validateQuantityFromInput(event)
});

$(document).on('focusout', 'input.input_actual_quantity', function() {
  changeActualBoxesQuantityFromInput(this)
  order_amount = $(this).val()

  if (0 >= order_amount) {
    order_amount = '-'
  }

  $(this).parent().html(order_amount)
})

function changeActualBoxesQuantityFromInput(element) {
  var order_overview_container = $(element).closest('.order-overview')
  var article_id = order_overview_container.data('article-id')
  var new_order_amount = $(element).val()

  if (0 > new_order_amount || !new_order_amount) {
    new_order_amount = 0
  }

  $('.input-container[data-article-id="' + article_id + '"] .actual_quantity').val(new_order_amount)
  $('.product-stock[data-article-id="'  + article_id + '"] input[name="actual_quantity"]').val(new_order_amount)

  if ($(order_overview_container).find('.multiplied_target_quantity').length > 0) {
    var target_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .multiplied_target_quantity').val())
  } else {
    var target_amount = parseInt($('.input-container[data-article-id="' + article_id + '"] .target_quantity').val())
  }

  new_order_amount = Math.max(target_amount - new_order_amount, 0)
  updateMinOrderAmount(new_order_amount, article_id)

  $(order_overview_container).find('.order_quantity').html(new_order_amount)
  $('.input-container[data-article-id="' + article_id + '"] .order_quantity').val(new_order_amount)
  $('.product-stock[data-article-id="'  + article_id + '"] input[name="order_quantity"]').val(new_order_amount)
}

function validateQuantityFromInput(event) {
  var regex = new RegExp("^[0-9]+$");
  if (!regex.test(event.key)) {
      event.preventDefault();
      return false;
  }
};

function delay(fn, ms) {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}
